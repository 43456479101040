
import "./Contact.css";
import Footer from "comp/footer/footer";




const Contact = () => {

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap:"wrap",
      
      }}
    >
      
      <div className="cont contact">
  
<Footer/>
      </div>

    </div>
  );
};

export default Contact;
